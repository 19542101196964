import React from "react"
import { Button, CircularProgress } from "@material-ui/core"
import * as LayoutUtils from "./../helpers/layout-utils"

interface LoadMoreButtonProps {
  clickHandler: () => Promise<void>
  hasNextPage: boolean
  isLoading: boolean
}

const LoadMoreButton = (props: LoadMoreButtonProps) => {
  const classes = LayoutUtils.useStyles()
  if (!props.hasNextPage) {
    return null
  }

  return (
    <div className={classes.loadBtnWrapper}>
      <Button
        variant="contained"
        color="primary"
        disabled={props.isLoading}
        onClick={props.clickHandler}
        aria-label="Load more"
      >
        Load more
      </Button>
      {props.isLoading && (
        <CircularProgress size={24} className={classes.loadBtnProgress} />
      )}
    </div>
  )
}

export default LoadMoreButton

import * as DiscogsTypes from "./discogs-types"

const BASE_API_URL = process.env.GATSBY_DISCOGS_API_BASE_URL

export enum Types {
  INSTANCES = "instances",
  RELEASES = "releases",
  ARTISTS = "artists",
  LABELS = "labels",
}

export interface GetListResponse {
  items: DiscogsTypes.InstanceReleaseList | DiscogsTypes.ArtistLabelList
  cursor: string
  page: number
}

export interface GetInstanceReleaseListResponse extends GetListResponse {
  items: DiscogsTypes.InstanceReleaseList
}

export interface GetArtistLabelListResponse extends GetListResponse {
  items: DiscogsTypes.ArtistLabelList
}

export type InstanceReleasePageMap = {
  [key: string]: DiscogsTypes.InstanceReleaseList
}
export type ArtistLabelPageMap = {
  [key: string]: DiscogsTypes.ArtistLabelList
}

export type InstanceReleaseResponsePageMap = {
  [key: string]: GetInstanceReleaseListResponse
}
export type ArtistLabelResponsePageMap = {
  [key: string]: GetArtistLabelListResponse
}

export async function getInstanceItems(
  year?: number,
  cursor?: string
): Promise<GetInstanceReleaseListResponse> {
  const response = await getItems({ type: Types.INSTANCES, year, cursor })
  return response as GetInstanceReleaseListResponse
}

export async function getReleaseItems(
  year?: number,
  cursor?: string
): Promise<GetInstanceReleaseListResponse> {
  const response = await getItems({ type: Types.RELEASES, year, cursor })
  return response as GetInstanceReleaseListResponse
}

export async function getArtistItems(
  cursor?: string
): Promise<GetArtistLabelListResponse> {
  const response = await getItems({ type: Types.ARTISTS, cursor })
  return response as GetArtistLabelListResponse
}

export async function getLabelItems(
  cursor?: string
): Promise<GetArtistLabelListResponse> {
  const response = await getItems({ type: Types.LABELS, cursor })
  return response as GetArtistLabelListResponse
}

export async function getInstanceItem(
  id: string
): Promise<DiscogsTypes.InstanceReleaseEntry> {
  const item = await getItem(Types.INSTANCES, id)
  return item as DiscogsTypes.InstanceReleaseEntry
}

export async function getReleaseItem(
  id: string
): Promise<DiscogsTypes.InstanceReleaseEntry> {
  const item = await getItem(Types.RELEASES, id)
  return item as DiscogsTypes.InstanceReleaseEntry
}

export async function getArtistItem(
  id: string
): Promise<DiscogsTypes.ArtistLabelItem> {
  const item = await getItem(Types.ARTISTS, id)
  return item as DiscogsTypes.ArtistLabelItem
}

export async function getLabelItem(
  id: string
): Promise<DiscogsTypes.ArtistLabelItem> {
  const item = await getItem(Types.LABELS, id)
  return item as DiscogsTypes.ArtistLabelItem
}

const doRequest = async (url: string): Promise<any> => {
  const response = await fetch(url)
  const body = await response.json()

  return body
}

interface GetItemsOpts {
  type: Types
  year?: number
  page?: number
  cursor?: string
}

const getItems = async (
  opts: GetItemsOpts
): Promise<GetInstanceReleaseListResponse | GetArtistLabelListResponse> => {
  const url = BASE_API_URL + "/" + opts.type
  const params = new URLSearchParams()
  if (opts.year) {
    params.set("year", opts.year.toString())
  }
  if (opts.cursor && opts.cursor.length > 0) {
    params.set("cursor", opts.cursor)
  }
  const queryString = params.toString()
  const endpoint = url + (queryString.length > 0 ? "?" + queryString : "")
  const response = await doRequest(endpoint)
  return {
    items: response.items,
    cursor: response.nextPage,
    page: response.page,
  }
}

export async function getItemInstances(
  type: Types,
  id: string,
  cursor?: string
): Promise<GetInstanceReleaseListResponse> {
  const url = BASE_API_URL + "/" + type + "/" + id + "/instances"
  const params = new URLSearchParams()
  if (cursor && cursor.length > 0) {
    params.set("cursor", cursor)
  }
  const queryString = params.toString()
  const endpoint = url + (queryString.length > 0 ? "?" + queryString : "")
  const response = await doRequest(endpoint)

  return {
    items: response.items,
    cursor: response.nextPage,
    page: response.page,
  }
}

export async function getItem(
  type: Types,
  id: string
): Promise<DiscogsTypes.InstanceReleaseItem | DiscogsTypes.ArtistLabelItem> {
  const url = BASE_API_URL + "/" + type + "/" + id
  const response = await doRequest(url)
  return response.item
}

import * as DiscogsTypes from "./discogs-types"
import {
  InstanceReleaseResponsePageMap,
  ArtistLabelResponsePageMap,
  GetArtistLabelListResponse,
  GetInstanceReleaseListResponse,
} from "./discogs-utils"

export function getItem(key: string): string | null {
  if (sessionStorage) {
    const cache = sessionStorage.getItem(key)
    if (cache !== null) {
      return cache
    }
  }

  return null
}

export function setItem(key: string, data: string) {
  if (sessionStorage) {
    sessionStorage.setItem(key, data)
  }
}

export function getPageFromCache(
  key: string,
  cursor: string
): GetInstanceReleaseListResponse | GetArtistLabelListResponse | null {
  if (sessionStorage) {
    const cache = sessionStorage.getItem(key)
    if (cache) {
      const responses:
        | InstanceReleaseResponsePageMap
        | ArtistLabelResponsePageMap = JSON.parse(cache)
      if (responses[cursor]) {
        return responses[cursor]
      }
    }
  }

  return null
}

export function savePageToCache(
  key: string,
  pageIdx: string,
  response: GetInstanceReleaseListResponse | GetArtistLabelListResponse
) {
  if (sessionStorage) {
    const cache = sessionStorage.getItem(key)
    const responses = cache ? JSON.parse(cache) : {}
    responses[pageIdx] = response

    sessionStorage.setItem(key, JSON.stringify(responses))
  }
}

export function getItemFromCache(
  key: string
): DiscogsTypes.InstanceReleaseEntry | DiscogsTypes.ArtistLabelEntry | null {
  if (sessionStorage) {
    const cache = sessionStorage.getItem(key)
    const item = cache ? JSON.parse(cache) : null

    return item
  }

  return null
}

export function saveItemToCache(
  key: string,
  item: DiscogsTypes.InstanceReleaseEntry | DiscogsTypes.ArtistLabelEntry
) {
  if (sessionStorage) {
    sessionStorage.setItem(key, JSON.stringify(item))
  }
}

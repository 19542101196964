import React, { ReactNode } from "react"
import {
  Container,
  createMuiTheme,
  createStyles,
  CssBaseline,
  Theme,
  ThemeProvider,
} from "@material-ui/core"
import Navigation from "./navigation"
import { makeStyles } from "@material-ui/core/styles"
import BackToTop from "./back-to-top"
import { Helmet } from "react-helmet"

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
  },
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: "20px",
      marginBottom: "20px",
    },
  })
)

interface PageProps {
  navTitle: string
  children: ReactNode
}

export default function Page(props: PageProps) {
  const classes = useStyles()

  const { navTitle, children } = props

  return (
    <ThemeProvider theme={darkTheme}>
      <Helmet>
        <meta
          name="description"
          content="An OK collection of mostly punk and hardcore records"
        />
      </Helmet>
      <CssBaseline />
      <Navigation title={navTitle} />
      <Container maxWidth="lg" className={classes.container}>
        {children}
        <BackToTop />
      </Container>
    </ThemeProvider>
  )
}

import React, { useEffect, useState } from "react"
import {
  AppBar,
  createStyles,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Link as MuiLink,
  Button,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import CloseIcon from "@material-ui/icons/Close"
import { useLocation, useNavigate } from "@reach/router"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      flexGrow: 1,
    },
    list: {
      width: "250px",
    },
    activeLink: {
      backgroundColor: "rgba(255,255,255,0.16)",
    },
    buttonLink: {
      color: "inherit",
      textDecoration: "none",
    },
  })
)

interface NavigationProps {
  title: string
}

interface NavLinks {
  [key: string]: string
}

const buildLinks = (
  links: NavLinks,
  closeHandler: () => void,
  classes: { [key: string]: any }
) => {
  const listItems = Object.keys(links).map((path, idx) => {
    return (
      <ListItem
        button
        component={Link}
        to={path}
        activeClassName={classes.activeLink}
        partiallyActive={true}
        key={idx}
      >
        <ListItemText primary={links[path]} />
      </ListItem>
    )
  })

  return (
    <List onClick={closeHandler} className={classes.list}>
      <ListItem>
        <ListItemIcon>
          <CloseIcon />
        </ListItemIcon>
      </ListItem>
      <Divider />
      {listItems}
    </List>
  )
}

export default function Navigation(props: NavigationProps) {
  const [navOpen, setNavOpen] = useState<boolean>(false)
  const [hasBackLink, setHasBackLink] = useState<boolean>(false)

  const menuHandler = () => {
    setNavOpen(prev => {
      return !prev
    })
  }

  const closeHandler = () => {
    setNavOpen(false)
  }

  const classes = useStyles()

  const { title } = props

  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (/^\/collection/.test(pathname)) {
      setHasBackLink(true)
    } else {
      setHasBackLink(false)
    }
  }, [pathname])

  let backButton = null
  if (hasBackLink) {
    backButton = (
      <MuiLink
        onClick={() => {
          navigate(-1)
        }}
        className={classes.buttonLink}
      >
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          aria-label="Go back"
        >
          Back
        </Button>
      </MuiLink>
    )
  }

  const navLinks: NavLinks = {
    "/releases": "Releases",
    "/instances": "Records",
    "/artists": "Artists",
    "/labels": "Labels",
  }
  const links = buildLinks(navLinks, closeHandler, classes)

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" onClick={menuHandler} aria-label="Menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {backButton}
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Drawer open={navOpen} onClose={closeHandler} variant="temporary">
        {links}
      </Drawer>
    </div>
  )
}

import React, { useState, useCallback, useRef, useEffect } from "react"
import { Fab } from "@material-ui/core"
import ArrowUpIcon from "@material-ui/icons/ArrowUpward"
import * as LayoutUtils from "../helpers/layout-utils"

const BackToTop = () => {
  const [scrollY, setScrollY] = useState<number>(0)

  const scrollInterval = useRef<NodeJS.Timeout | null>(null)
  const doScroll = useCallback(() => {
    if (scrollInterval.current) {
      if (window.scrollY <= 0) {
        clearInterval(scrollInterval.current)
      } else {
        window.scrollTo(window.scrollX, window.scrollY - 75)
      }
    }
  }, [scrollInterval])

  const scrollToTop = useCallback(() => {
    scrollInterval.current = setInterval(doScroll, 10)
  }, [scrollInterval, doScroll])

  const classes = LayoutUtils.useStyles()

  useEffect(() => {
    setScrollY(window.scrollY)

    setInterval(() => {
      setScrollY(window.scrollY)
    }, 100)
  }, [])

  if (scrollY > 0) {
    return (
      <Fab
        className={classes.backToTopBtn}
        onClick={scrollToTop}
        aria-label="Scroll back to top"
      >
        <ArrowUpIcon />
      </Fab>
    )
  } else {
    return null
  }
}

export default BackToTop
